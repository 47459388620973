export const lightTheme = {
  primary: '#84A3D9',
  'primary-content': '#FFF6FF',

  secondary: '#E5E5E5',
  'secondary-content': '#FFF6FF',

  accent: '#84A3D9',
  'accent-content': '#FFF6FF',

  neutral: '#B7DDF4',
  'neutral-content': '#FFF6FF',

  highlight: '#CADCFC',

  'base-100': '#FFF',
  'base-200': '#f5f5f5',
  'base-300': '#808080',
  'base-content': '#000000',
  'base-100-content': '#000000',
  'base-200-content': '#000000',
  'base-300-content': '#000000',

  info: '#F6F0FE',
  'info-content': '#89BCDA',

  success: '#479F91',
  'success-content': '#fff',

  warning: '#FAB251',
  'warning-content': '#000',

  error: '#F24949',
  'error-content': '#ffffff',
};

export const darkTheme = {
  primary: '#84A3D9',
  'primary-content': '#FFF6FF',

  secondary: '#155DA6',
  'secondary-content': '#FFF6FF',

  accent: '#84A3D9',
  'accent-content': '#FFF6FF',

  neutral: '#978FA3',
  'neutral-content': '#FFF6FF',

  highlight: '#CADCFC',

  'base-100': '#00246B',
  'base-200': '#083185',
  'base-300': '#FFFFFF',
  'base-content': '#FFF6FF',

  'base-100-content': '#FFF6FF',
  'base-200-content': '#FFF6FF',
  'base-300-content': '#FFF6FF',

  info: '#230351',
  'info-content': '#B7DDF4',

  success: '#479F91',
  'success-content': '#fff',

  warning: '#FAB251',
  'warning-content': '#000',

  error: '#F24949',
  'error-content': '#fff',
};
