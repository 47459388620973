import { Guess } from '@wt/app/game/_components/types';
import { getSiteMode } from '@wt/utilities/siteMode';

function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export const MAX_DISTANCE_SCORE = 100;
export const MAX_YEAR_SCORE = 100;

export function getMaxScore() {
  if (getSiteMode() == 'whentaken') {
    return MAX_YEAR_SCORE + MAX_DISTANCE_SCORE;
  } else {
    return MAX_DISTANCE_SCORE;
  }
}

const SAME_COUNTRY_POINTS = 10;

export interface ScoreResultProps {
  distanceText: string;
  yearsText: string;
  distance: number;
  yearsOff: number;
  score: {
    total: number;
    year: number;
    distance: number;
  };
}

const guessScores: Record<string, ScoreResultProps> = {};

export function getGameScore(guess: Guess, unit?: string): ScoreResultProps {
  const key =
    getSiteMode() == 'whentaken'
      ? `${guess.year} ${guess.location} ${guess.puzzle.puzzle}`
      : `${guess.location} ${guess.puzzle.puzzle}`;
  if (key in guessScores) {
    return guessScores[key];
  }

  const yearsOff =
    getSiteMode() == 'whentaken'
      ? Math.abs(guess.year - Number(guess.puzzle.year))
      : 0;
  const distance =
    1000 *
    getDistanceFromLatLonInKm(
      Number(guess.location.latitude),
      Number(guess.location.longitude),
      guess.puzzle.latitude,
      guess.puzzle.longitude
    );
  let distanceText: string;
  if (distance < 1000) {
    let dst = distance;
    let suffix = 'metres';
    switch (unit) {
      case 'miles':
        {
          const factor = 3.28084;
          dst = dst * factor;
          suffix = 'feet';
        }
        break;
    }
    distanceText = dst.toFixed(1) + ' ' + suffix;
  } else {
    let dst = distance / 1000;
    let suffix = 'km';
    switch (unit) {
      case 'miles':
        {
          const factor = 0.621371;
          dst = dst * factor;
          suffix = 'miles';
        }
        break;
    }

    distanceText = Math.round(Number(dst.toFixed(0))) + ' ' + suffix;
  }

  let yearsText: string;
  if (yearsOff == 0) {
    yearsText = 'You got it spot on!';
  } else if (yearsOff == 1) {
    yearsText = 'You were 1 year off';
  } else {
    yearsText = 'You were ' + yearsOff + ' years off';
  }

  const getYearScore = (yearsOff: number) => {
    let yearScore: number;

    if (yearsOff <= 5) {
      yearScore = MAX_YEAR_SCORE - yearsOff;
    } else if (yearsOff <= 10) {
      yearScore = MAX_YEAR_SCORE - 5 - (yearsOff - 5) * 2;
    } else if (yearsOff <= 20) {
      yearScore = MAX_YEAR_SCORE - 15 - (yearsOff - 10) * 3;
    } else {
      yearScore = MAX_YEAR_SCORE - 45 - (yearsOff - 20) * 4;
    }

    return yearScore < 0 ? 0 : Math.round(yearScore);
  };

  const getDistanceScore = (dist: number, sameCountry: boolean) => {
    const d = Math.round(dist / 1000); // distance off in km
    let distanceScore: number;
    const sameCountryBonus = sameCountry ? SAME_COUNTRY_POINTS : 0;

    if (d <= 100) {
      distanceScore = MAX_DISTANCE_SCORE - d * 0.05;
    } else if (d <= 500) {
      distanceScore = 95 - (d - 101) * 0.025 + sameCountryBonus;
    } else if (d <= 1000) {
      distanceScore = 85 - (d - 501) * 0.03 + sameCountryBonus;
    } else if (d <= 2000) {
      distanceScore = 70 - (d - 1001) * 0.02 + sameCountryBonus;
    } else if (d <= 5000) {
      distanceScore = 50 - (d - 2001) * 0.01 + sameCountryBonus;
    } else if (d <= 10000) {
      distanceScore = 20 - (d - 5001) * 0.004 + sameCountryBonus;
    } else {
      distanceScore = sameCountryBonus;
    }

    return distanceScore < 0 ? 0 : Math.round(distanceScore);
  };

  const yearScore = getSiteMode() == 'whentaken' ? getYearScore(yearsOff) : 0;
  const distanceScore = getDistanceScore(
    distance,
    guess.correctCountry ?? false
  );
  const totalScore = yearScore + distanceScore;

  const result: ScoreResultProps = {
    distanceText: distanceText,
    yearsText: yearsText,
    distance: distance,
    yearsOff: yearsOff,
    score: {
      total: Math.round(totalScore),
      year: Math.round(yearScore),
      distance: Math.round(distanceScore),
    },
  };
  guessScores[key] = result;
  return result;
}
